$env: production;
@import "variables";
@import "functions";

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

html {
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  width: 100% !important;
  font-family: "PingFang SC", STHeitiSC-Light, Helvetica-Light, arial, sans-serif;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

button:not(:disabled) {
  cursor: pointer;
}

input[type="password"] {
  font-family: Helvetica-Light, arial, sans-serif;
}

@media (any-hover: hover) {
  html {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
    }

    ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.12);

      &:hover {
        background-color: rgba(0, 0, 0, 0.18);
      }
    }
  }
}
