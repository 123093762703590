$env: production;
.setting {
  &__title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }

  &__checkbox {
    display: flex;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__checkbox-item {
    position: relative;
    cursor: pointer;
    border-radius: 2px;

    & + & {
      margin-left: 16px;
    }
  }

  &__checkbox-img {
    width: 48px;
    height: auto;
    vertical-align: top;
  }

  &__icon-checked {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 700;
    color: #1890ff;
  }

  &__nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
  }
}
