$env: production;
@use "sass:math";

p {
  &.no-margin,
  .ant-table-cell & {
    margin: 0;
  }
}

pre {
  margin: 0;

  &.code {
    white-space: normal;
    cursor: pointer;
  }
}

.fr {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

td.no-padding-left {
  padding-left: 0 !important;
}

td.no-padding-right {
  padding-right: 0 !important;
}

td.no-padding-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.clearfix {
  zoom: 1;

  &::after {
    display: block;
    height: 0;
    clear: both;
    content: "\20";
  }
}

a.disabled {
  color: rgba(0, 0, 0, 0.25);
  text-shadow: none;
  pointer-events: none;
  cursor: not-allowed;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.width-full {
  width: 100%;
}

.common {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 21, 41, 0.04);

  &__header {
    position: relative;
    z-index: 3;
    display: flex;
    padding: 12px 8px;
    box-shadow: 0 2px 4px 0 rgba(250, 250, 250, 0.9);

    .ant-form-inline {
      .ant-form-item:last-child {
        margin-right: 0;
      }
    }
  }

  &__editable-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  // 表格行内编辑,切换输入框时不抖动
  &__editable-cell-content {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 32px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 6px;

    .ant-table-row:hover &:not(.no-border-color) {
      border-color: #d9d9d9;
    }

    &.no-border-color {
      border-color: transparent;
    }
  }
}

.common-modal {
  .ant-form-vertical {
    .ant-form-item-label {
      padding-bottom: 6px;
    }
  }

  &.example-image {
    .ant-image,
    .ant-image-img {
      width: 100%;
    }

    .ant-image-img {
      min-height: 150px;
      border: 1px dashed #d9d9d9;
    }
  }
}

.qrcode {
  width: 150px !important;
  height: 150px !important;
  vertical-align: top;
}

.cursor {
  cursor: pointer;
}

.chart-table {
  width: 100%;
  text-align: center;
  border-spacing: 0;
  border-collapse: separate;

  th,
  td {
    min-width: 100px;
    height: 30px;
    background: #fff;
    border-right: 1px solid #a8aeb2;
    border-bottom: 1px solid #a8aeb2;

    &:first-child {
      position: sticky;
      left: 0;
      background: #fafafa;
      border-left: 1px solid #a8aeb2;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;

    th {
      background: #fafafa;
      border-top: 1px solid #a8aeb2;
    }

    p {
      width: 100px;
      padding: 0 4px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      &:last-child {
        font-weight: 600;
      }
    }
  }
}

.map-gps {
  width: 40px;
  height: 40px;
  padding: 0 5px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 38px;
  color: #666;
  text-align: center;
  cursor: pointer;
  background: rgb(255, 255, 255) url("../icon/gps.svg") no-repeat center / 24px;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 6px;
}

.map-search {
  width: 200px;
  margin: 10px 0 0 10px;
}

// 谷歌地图搜索下来框

.pac-container {
  margin-top: 2px;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.pac-item {
  line-height: 32px;
  border: none;
}

.hdpi.pac-logo::after {
  content: none;
}

// 自定义下拉框清除按钮样式
.select-clear-button {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  background: none;
  border: none;
  outline: none;
  transform: translate(-50%, -50%);
}

/** ant 样式修改
***************************/
// 引导
.ant-tour {
  max-width: 90%;
}

// 头像不要透明边框
.ant-avatar {
  border: none !important;
}

// 避免移动端滚动条被遮挡
.ant-table,
.ant-table-content {
  position: relative;
  z-index: 2;
}

.ant-table-placeholder {
  height: 167px;
}

// 下拉菜单选项文字不换行
.ant-dropdown-menu-title-content {
  white-space: nowrap;
}

// 弹窗头部
.ant-modal-header {
  margin-bottom: 12px !important;
}

// 居中弹窗 视图高度-外边距-头部-尾部-内边距
.ant-modal-body {
  padding: 0 5px;
  margin: 0 -5px;

  .ant-modal-centered & {
    max-height: calc(var(--inner-height) - 16px - 36px - 44px - 40px);
    overflow-y: auto;
  }
}

// 弹窗，侧边栏，确认弹窗，滚动样式
.ant-modal-body,
.ant-drawer-body,
.ant-modal-confirm-content {
  overscroll-behavior: contain;
}

// 头像上传，图片超出隐藏
.ant-upload {
  .ant-upload-picture-circle-wrapper & {
    overflow: hidden;
  }
}

// 徽标数字层级提高
.ant-badge-count {
  z-index: 2 !important;
}

// 确认框内容高度最大限制
.ant-modal-confirm-content {
  max-height: 400px;
  overflow-y: auto;
}

// 描述表格下的pre标签样式
.ant-descriptions pre {
  margin: 0;
  word-break: break-all;
  white-space: pre-line;
}

// 自定的表格省略
div.ant-table-cell-ellipsis {
  display: inline-block;
  max-width: 100%;
  line-height: 1.25;
  vertical-align: middle;
}

.ant-rate {
  line-height: 1;
  vertical-align: top;
}

.ant-table-tbody {
  .ant-image {
    width: auto !important;
    margin: 5px 10px 5px 0;
    vertical-align: top;
  }

  .ant-image-img {
    width: auto;
    height: initial;
  }
}

.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: top;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: auto;
}

// 输入框100%宽度
.ant-picker,
.ant-input-number,
.ant-input-number-group-wrapper,
.ant-input-number-affix-wrapper {
  .ant-form-item & {
    width: 100%;
  }
}

.ant-table-pagination.ant-pagination {
  position: relative;
  z-index: 3;
  padding: 16px 8px 16px 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: 0 -1px 4px 0 rgba(240, 240, 240, 0.5);
}

.ant-table-summary,
.ant-table-summary td {
  background-color: #fafafa;
}

// noinspection Stylelint
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell)::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  content: "";
  background-color: rgba(0, 0, 0, 0.06);
  transition: background-color 0.3s;
  transform: translateY(-50%);
}

.ant-input-status-error,
.ant-select-status-error,
.ant-input-number-status-error {
  z-index: 1;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-number:focus,
.ant-select-focused,
.ant-input-number-focused {
  z-index: 2;
}

.ant-drawer-content-wrapper {
  .drawer-720 & {
    width: 720px !important;

    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.ant-table-expanded-row {
  .ant-descriptions-bordered {
    .ant-descriptions-item-label {
      min-width: 100px;
      padding-left: 0;
      text-align: right;
    }
  }
}

@media screen and (max-width: 576px) {
  // 窄屏幕图片预览不显示翻转
  .ant-image-preview-operations-operation:nth-last-of-type(2),
  .ant-image-preview-operations-operation:last-of-type {
    display: none;
  }

  .ant-result-content {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: -32px;
    margin-left: -32px;
  }

  // 小屏幕，显示分页大小选项
  .show-size-changer {
    .ant-pagination-options {
      display: initial !important;
    }

    .ant-pagination-options-quick-jumper {
      display: none !important;
    }
  }
}

@media (any-hover: hover) {
  .common-modal {
    overflow-y: overlay;
  }
}

// H5样式
@media (any-hover: none) {
  .common {
    border-radius: 0;
  }

  // 下拉框清除按钮直接显示
  .ant-select-clear {
    opacity: 1 !important;
  }

  // 上传文件的预览遮罩和操作按钮，直接显示，不用hover
  .ant-upload-list-item *,
  .ant-upload-list-item::before, {
    opacity: 1 !important;
  }
}
