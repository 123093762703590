$env: production;
@use "sass:math";
@import "@/assets/scss/bem";

$header-height: 48px;
$icon-height: 32px;

/* stylelint-disable no-descending-specificity */
.layout {
  display: flex;
  width: 100%;
  height: 100%;

  &__aside,
  &__container {
    height: 100%;
  }

  &__aside {
    flex: 0 0 auto;
  }

  &__container {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    overflow: auto;
    background: #f0f2f5;
  }

  &__header,
  &__content {
    width: 100%;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
    flex: 0 0 auto;
    user-select: none;
    background-color: #fff;
  }

  &__content {
    position: relative;
    z-index: 1;
    flex: 1 1 0;
    height: 100%;
    padding: 10px;
    overflow-y: auto;
  }
}

.menu,
.tabs {
  a {
    color: inherit;
  }
}

.aside {
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  background-color: inherit;
  transition: background-color 0.3s;

  &.dark {
    background-color: #001529;

    @include e(inner) {
      box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    }
  }

  &.light {
    background-color: #fff;

    @include e(inner) {
      box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    }
  }
}

.title {
  flex: 0 0 auto;
  height: $header-height;
  padding: math.div($header-height - $icon-height, 2);
  margin: 0;
  overflow: hidden;
  font-size: 17px;
  font-weight: 400;
  line-height: $icon-height;
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &__icon {
    width: auto;
    height: $icon-height;
    margin-right: 8px;
    vertical-align: top;
    border-radius: 6px;
  }

  &.dark {
    color: #fff;
  }

  &.light {
    color: #333;
  }
}

.menu {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  border-right: none;

  :global {
    .ant-menu-item,
    .ant-menu-submenu-title {
      transition: border-color 0.3s, background 0.3s !important;
    }

    .ant-menu-submenu-arrow {
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }
  }

  &:global(.ant-menu-inline-collapsed) {
    width: 100%;
  }

  &:global(.ant-menu-horizontal) {
    overflow-y: hidden;

    :global {
      .ant-menu-item,
      .ant-menu-submenu {
        top: 0;
        margin-top: 0;
      }
    }
  }
}

.header {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: $header-height;
  line-height: $header-height;
  background-color: inherit;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  transition: background 0.3s, width 0.2s;

  &__left {
    display: flex;
    flex: 0 0 auto;
    height: inherit;
  }

  &__center {
    flex: 1 1 auto;
    height: inherit;
    overflow: hidden;
  }

  &__right {
    display: flex;
    flex: 0 0 auto;
    float: right;
    height: inherit;
    overflow: hidden;
  }

  &__action {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }

  &__avatar {
    color: #f56a00;
    background-color: #fde3cf;
  }

  &__username {
    margin-left: 8px;
    font-size: 14px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &.dark {
    color: hsla(0deg, 0%, 100%, 0.85);
    background-color: #001529;

    @include e(action) {
      &:hover {
        background-color: #1890ff;
      }
    }
  }

  &.light {
    background-color: #fff;
  }
}

.tabs {
  &:global(.ant-tabs) {
    position: relative;
    z-index: 1;
    padding-left: 8px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.04);

    :global {
      .ant-tabs-nav {
        height: 34px;
        margin: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}

.menu-drawer {
  &:global(.ant-drawer-content) {
    :global {
      .ant-drawer-body {
        padding: 0;
      }

      .ant-layout-sider {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  // noinspection Stylelint
  .layout__content {
    padding: 0;
  }
}

@media (any-hover: hover) {
  // noinspection Stylelint
  .layout__content,
  .menu {
    overflow-y: overlay;
  }

  .aside.dark {
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
    }

    ::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.15);

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
