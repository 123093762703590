$env: production;
@use "sass:math";

.user {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: url("./img/bg.svg") repeat-y top left;
  background-size: 100% auto;

  &__header {
    flex: 0 0 auto;
    padding: 10vh 0 40px;
    overflow: hidden;
    text-align: center;
  }

  &__title {
    height: 44px;
    font-size: 28px;
    font-weight: 400;
    line-height: 44px;
    color: #333;
  }

  &__logo {
    width: auto;
    height: 100%;
    margin-right: 10px;
    vertical-align: top;
    border-radius: 12px;
  }

  &__subtitle {
    margin: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }

  &__content {
    flex: 1 1 auto;
    width: 328px;
    margin: 0 auto;
  }

  :global {
    .ant-tabs-nav {
      margin-bottom: 30px;
    }
  }
}

.footer {
  flex: 0 0 auto;
  width: 100%;
  padding: 24px 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;

  &__copyright {
    color: rgba(0, 0, 0, 0.5);
  }

  &__links {
    margin-bottom: 12px;
    text-align: center;
  }

  :global {
    .ant-btn:not(:hover) {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.login {
  position: relative;
  z-index: 2;

  &__remember {
    margin-bottom: 24px;
  }

  &__link-register {
    text-align: center;
  }

  &__captcha {
    float: right;
    width: 120px;
    height: 40px;
    vertical-align: top;
    cursor: pointer;
    background: linear-gradient(180deg, #d9ecff 0, #d9dadc);
    border-radius: 2px;

    svg {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 576px) {
  .user {
    &__header {
      padding-top: 6vh;
    }

    &__content {
      width: 90%;
    }
  }

  .header {
    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
